@import "~antd/dist/antd.css";

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}

html,
body {
	width: 100%;
	min-width: 320px;
	min-height: 100vh;
	background-color: #ffffff;
	position: relative;
	overscroll-behavior: none;
	overflow: hidden;
	color: #333;
	font-size: 14px;
}

body,
#root {
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
}

img {
	display: inline-block;
	vertical-align: top;
	border: 0;
	user-select: none;
	-webkit-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-modify: read-only;
}

a {
	color: inherit;
	text-decoration: none;
}

ul > li,
ol > li {
	list-style: none;
}

input,
textarea,
button {
	border: none;
	outline: none;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: #314559;
	-webkit-appearance: none;
}

::-webkit-scrollbar-track {
	width: 8px;
	background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
	background-color: #B1B4B9;
	border-radius: 4px;
}

textarea {
	resize: none;
}

input:disabled,
textarea:disabled {
	background-color: #ffffff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
	display: none;
}
